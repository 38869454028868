<template>
  <!-- <div class="W-100 D-Flex FlexWrap DateContainer" style="justify-content: center;">
    <div><span>{{LanguageSelector('From', 'من')}}</span><input @change="DateChanged(DateFrom, DateTo)" v-model="DateFrom" class="CookiesInput color2" type="date"></div>
    <div><span>{{LanguageSelector('To', 'الى')}}</span><input @change="DateChanged(DateFrom, DateTo)" v-model="DateTo" class="CookiesInput color2" type="date"></div>
  </div>
  <div id='CookiesCardsOrganizer' class='CookiesMainView' :class="$store.state.CookiesMemory.DashCardOnhold ? 'NoUserSelect' : ''">
    <Lazy
      v-for='(value, index) in CookiesMainContent'
      :CardContent='value.CardContent'
      :key='(value, index)'
      :style='{ order: value.order }'
      class='CookiesCardSize CookiesCardPlaceHolder'
      :class='{ [value.cardSize]: true, CookiesCardPlaceHolderDashes: CardIsDragging }'
      @mousemove='CardIsDragging ? MousePlace() : null'
    >
      <cookiesCard
        @IamDragging='MyCardIsDragging()'
        @DraggingStopped='DraggingStopped()'
        :class='value.cardSize'
        :number='index'
        :CardContent='value.CardContent'
      />
    </Lazy>
  </div> -->
  <div class="W-100 H-100 D-Flex Shadow2" style="max-height: calc(100vh - 100px);">
    <img style="max-width: 35vw; max-height: 35vh; margin: auto;" :src="DefaultImageHandler($store.state.CookiesConfig.Logo, 'https://cookiesbis.com/assets/Logo.svg')" alt="">
  </div>
</template>

<script>
import CookiesCard from '@/components/CookiesCard.vue'

export default {
  beforeCreate () {}
  // components: {
  //   CookiesCard
  // },
  // beforeMount () {
  //   var GetItem = this.$store.state.CookiesConfig.Cards
  //   if (GetItem !== null & GetItem !== undefined) {
  //     var SavedCards = JSON.parse(GetItem)
  //     if (this.CookiesMainContent.length > SavedCards.length) {
  //       this.EditConfig('Cards', JSON.stringify(this.CookiesMainContent))
  //     } else {
  //       this.CookiesMainContent = JSON.parse(GetItem)
  //     }
  //   }
  // },
  // mounted () {
  //   if (!this.Access('MainView')) {
  //     this.$router.replace('/Orders')
  //   }
  //   this.ReadConfigData()
  // },
  // data () {
  //   return {
  //     DateFrom: this.$store.state.CurrentDate.From,
  //     DateTo: this.$store.state.CurrentDate.To,
  //     CardOverNow: null,
  //     DraggedCard: null,
  //     CardIsDragging: false,
  //     ChangingCards: false,
  //     OriginalConfig: {},
  //     CookiesMainContent: [
  //       {
  //         cardSize: 'CookiesCardSizeSM',
  //         order: 1,
  //         CardContent: 'TicketsCard'
  //       },
  //       {
  //         cardSize: 'CookiesCardSizeSM',
  //         order: 3,
  //         CardContent: 'CashCard'
  //       },
  //       {
  //         cardSize: 'CookiesCardSizeSM',
  //         order: 5,
  //         CardContent: 'CustomersOrdersCard'
  //       },
  //       {
  //         cardSize: 'CookiesCardSizeLG',
  //         order: 4,
  //         CardContent: 'ProfitCard'
  //       },
  //       {
  //         cardSize: 'CookiesCardSizeSM',
  //         order: 6,
  //         CardContent: 'DriversCard'
  //       },
  //       {
  //         cardSize: 'CookiesCardSizeLG',
  //         order: 2,
  //         CardContent: 'OrdersFlowCard'
  //       }
  //     ]
  //   }
  // },
  // methods: {
  //   async ReadConfigData () {
  //     this.Get('Config').then(response => {
  //       // this.OriginalConfig = this.DeepCloner(response)
  //       response.forEach(element => {
  //         this.$store.state.CookiesConfig[element.Name] = element.value
  //         this.$store.state.CookiesConfig[element.Name + 'ID'] = element.ID
  //         this.OriginalConfig[element.Name] = element.value
  //         this.OriginalConfig[element.Name + 'ID'] = element.ID
  //       })
  //       this.Console(this.OriginalConfig)
  //       // this.OriginalConfig = this.DeepCloner(this.$store.state.CookiesConfig)
  //     }, error => {
  //       error.CookiesError = 'Error in reading Cookies Config'
  //       this.OpenDialog('ErrorDialog', 'Error', error) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
  //     })
  //   },
  //   async MyCardIsDragging () {
  //     this.CardIsDragging = true
  //   },
  //   async MousePlace () {
  //     var CookiesCardstoOrganize = document.getElementById('CookiesCardsOrganizer')
  //       .children
  //     this.DraggedCard = event.target.parentElement.parentElement.style.order
  //     var CardsOverNow = document.elementsFromPoint(event.clientX, event.clientY)
  //     // console.log(CardsOverNow)
  //     var filteredArray = CardsOverNow.filter(function (element) {
  //       return element.classList.contains('CookiesCardPlaceHolder')
  //     })
  //     this.CardOverNow = filteredArray[0]
  //     if (this.CardOverNow != null) {
  //       var CookiesCardNum = this.DraggedCard
  //       // console.log('this.CardOverNow: ')
  //       // console.log(this.CardOverNow)
  //       var CookiesPlaceNumber = this.CardOverNow.style.order
  //       if (CookiesPlaceNumber !== CookiesCardNum && !this.ChangingCards) {
  //         this.ChangingCards = true
  //         var CookiesCardsArray = Array.prototype.slice.call(CookiesCardstoOrganize)
  //         // console.log(CookiesCardsArray)
  //         var TheLastElement = CookiesCardsArray.filter(function (element) {
  //           return element.style.order === CookiesPlaceNumber
  //         })
  //         try {
  //           var TheLastElementName = this.CookiesMainContent.find(function (obj) {
  //             return obj.CardContent === TheLastElement[0].getAttribute('CardContent')
  //           })
  //         } catch (error) {
  //         }
  //         // console.log(TheLastElement[0].getAttribute('CardContent'))
  //         // console.log(TheLastElementName)
  //         try {
  //           var TheElement = CookiesCardsArray.filter(function (element) {
  //             return element.style.order === CookiesCardNum.toString()
  //           })
  //         } catch (error) {
  //         }
  //         try {
  //           var ElementName = this.CookiesMainContent.find(function (obj) {
  //             return obj.CardContent === TheElement[0].getAttribute('CardContent')
  //           })
  //           this.Console('CookiesPlaceNumber')
  //           this.Console(CookiesPlaceNumber)
  //           this.Console(CookiesCardNum)
  //           this.Console(ElementName)
  //           this.Console(TheLastElementName)
  //           if (CookiesCardNum != null && CookiesPlaceNumber != null && (CookiesPlaceNumber !== CookiesCardNum)) {
  //             TheLastElementName.order = null
  //             ElementName.order = parseInt(CookiesPlaceNumber, 10)
  //             TheLastElementName.order = parseInt(CookiesCardNum, 10)
  //             var CardsConfig = JSON.stringify(this.CookiesMainContent)
  //             this.EditConfig('Cards', CardsConfig)
  //           }
  //         } catch (error) {
  //         }
  //         this.CardOverNow = null
  //         this.DraggedCard = null
  //         CookiesPlaceNumber = null
  //         CookiesCardNum = null
  //         setTimeout(() => {
  //           this.ChangingCards = false
  //         }, 500)
  //       }
  //     }
  //   },
  //   SaveConfig (attr, value) {
  //     var newItem = {
  //       Name: attr,
  //       value: value
  //     }
  //     this.Console(newItem)
  //     this.Post('POST', 'Config/Add', newItem).then(response => {
  //       this.ReadConfigData()
  //       this.Saving = false
  //     }, error => {
  //       this.Saving = false
  //       error.CookiesError = 'Error in Saving Config'
  //       this.OpenDialog('ErrorDialog', 'Error', error) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
  //     })
  //   },
  //   EditConfig (attr, value) {
  //     var GetItem = this.OriginalConfig.Cards
  //     if (GetItem === null || GetItem === undefined) {
  //       this.SaveConfig(attr, value)
  //       return
  //     }
  //     var Patch = [
  //       {
  //         op: 'replace',
  //         path: '/value',
  //         value: value
  //       }
  //     ]
  //     if (Patch.length === 0) {
  //       this.Saving = false
  //       return
  //     }
  //     this.Post('PATCH', 'Config/Edit?ID=' + this.OriginalConfig.CardsID, Patch).then(response => {
  //       this.ReadConfigData()
  //       this.Saving = false
  //     }, error => {
  //       this.Saving = false
  //       error.CookiesError = 'Error in Editing Config'
  //       this.OpenDialog('ErrorDialog', 'Error', error) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
  //     })
  //   },
  //   DraggingStopped () {
  //     this.CardIsDragging = false
  //     this.ChangingCards = false
  //   }
  // }
}
</script>

<style scoped>
.CookiesMainView{
  padding-left: 5em;
  padding-right: 5em;
  margin-top: -4em;
}
.NoUserSelect>>>* {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.CookiesCardPlaceHolder {
  display: block;
  margin: 12px;
  /* border: 2px dashed rgba(0, 0, 0, 0); */
  transition: cubic-bezier(0.075, 0.82, 0.165, 1);
  transition-property: opacity;
  display: flex;
}
.CookiesCardPlaceHolderDashes {
  border: 2px dashed rgba(51, 51, 51, 0.39);
}

@media (max-width: 1000px) {
  .CookiesMainView{
    margin-top: -1em;
  }
}
</style>
